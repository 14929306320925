import React, {useState, useEffect, useCallback, useReducer} from 'react';
import {navigate} from 'gatsby';
import {useMutation} from '@apollo/client';
import qs from 'query-string';
import {Translate} from '../../components/translations';
import Input from '../form/input';
import Checkbox from '../form/checkbox';
import Message from '../messages';
import ChangeToFactor from '../changeToFactor';
import {TrackRegistration} from '../../helpers/trackingEvents';
import {USER_REGISTRATION} from '../../graphql/mutations';
import {
  setUserToken,
  setUserHash,
  setNewUser,
  setRefreshToken,
  setDeviceKey,
  VALID_PASSWORD_RE,
  getPackageInfo,
  returnDashUrl,
  getRawCookie,
  setRawCookie,
} from '../../helpers';
import {UserRegistrationData, UserRegistrationFormState, UserRegistrationSecondaryState} from './types';
import googleIcon from '../../images/google-icon.svg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {eyeIcon} from '../../images/svgIcons';
import useSetUser from '../../hooks/useSetUser';
import './styles.scss';

const Registration = ({
  setShowLogin,
  setShowRegistration,
  promoCode,
  location,
  setHandleCloseRegModal,
  state,
}: any): JSX.Element => {
  //!----------------State--------------------
  //Boolean
  const [passwordMessage, setPasswordMessage] = useState(false);
  const [showTwoFactor, setShowTwoFactor] = useState(false);
  const [showPassword, setShowPassword] = useReducer((val: boolean) => !val, false);
  const [referralCode, setReferral] = useState('');
  const [limitExceed, setLimitExceed] = useState(false);

  //Object
  const [formData, setFormData] = useState<UserRegistrationFormState>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    promoCode: promoCode || '',
    referralCode: '',
  });
  const [secondaryData, setSecondaryData] = useState<UserRegistrationSecondaryState>({
    checked: false,
    error: false,
    errorMessage: '',
    isModalOpen: false,
    sessionToken: '',
  });

  const [getUser] = useSetUser(null, () => {
    localStorage.setItem('fromLogonOrRegistrationPage', 'true');
    navigate(getPackageInfo() ? '/pricing/checkout' : state?.navigatePath ? state?.navigatePath : '/pricing/checkout');
  });

  //!---------------Mutation------------------
  const [registrationMutation, {loading}] = useMutation<UserRegistrationData>(USER_REGISTRATION);
  //!---------------UseEffect------------------
  useEffect(() => {
    //@ts-ignore
    if (global && global.window && global.window.location) {
      //@ts-ignore
      if (
        global &&
        global.window &&
        global.window.location &&
        global.window.location.search &&
        global.window.location.search.length > 0
      ) {
        //@ts-ignore
        const queryData = qs.parse(global.window.location.search);
        if (queryData && queryData['promo-code'] && queryData['promo-code'].length > 0) {
          setFormData({
            ...formData,
            promoCode: queryData['promo-code'] as string,
          });
        }
      }
    }
    //@ts-ignore
  }, [global && global.window && global.window.location]);

  useEffect(() => {
    if (location) {
      if (location.state && location.state.email) {
        setFormData({...formData, ['email']: location.state.email});
      }
    }
    const urlParams = new URLSearchParams(window.location.search);
    let referral = urlParams.get('referralCode');
    if (referral) {
      if (referral[referral.length - 1] === '/') {
        referral = referral.slice(0, -1);
        setFormData({...formData, referralCode: referral});
        setReferral(referral);
        setRawCookie('referralCode', referral);
      }
    }
  }, [location]);

  useEffect(() => {
    const ref = getRawCookie('referralCode');
    ref && setReferral(ref);
  }, []);

  useEffect(() => {
    if (showTwoFactor) {
      const el = document.getElementsByClassName('modal-body');
      el && el[0] && el[0].classList.add('modal-body-two-fa');
    } else {
      const el = document.getElementsByClassName('modal-body-two-fa');
      el && el[0] && el[0].classList.remove('modal-body-two-fa');
    }
  }, [showTwoFactor]);
  //!------------------Functions-------------
  const toLogin = useCallback(() => {
    setShowLogin && setShowLogin(true);
    setShowRegistration && setShowRegistration(false);
    navigate('/login');
  }, []);

  const fieldChangeHandler = useCallback(
    (event: React.SyntheticEvent): void => {
      const target = event.target as HTMLInputElement;
      const {value, name} = target;
      setFormData({
        ...formData,
        [name]: value,
      });
    },
    [formData],
  );

  const checkHandler = useCallback((): void => {
    setSecondaryData({
      ...secondaryData,
      checked: !secondaryData.checked,
    });
  }, [secondaryData]);

  const submitHandler = useCallback(async () => {
    if (formData.password.length > 64) {
      setLimitExceed(true);
      setPasswordMessage(false);
      return;
    }
    if (VALID_PASSWORD_RE.test(formData.password)) {
      setPasswordMessage(false);
      setLimitExceed(false);
    } else {
      setPasswordMessage(true);
      setLimitExceed(false);
      return;
    }

    setSecondaryData({...secondaryData, error: false, errorMessage: ''});
    if (secondaryData.checked) {
      TrackRegistration(formData);
      const {data} = await registrationMutation({
        variables: {
          ...formData,
          sessionToken: secondaryData.sessionToken,
        },
        context: {clientName: 'v2'},
      });

      if (data) {
        const {
          UserOps: {
            registration: {error, message, token, hash, device_key, refresh_token},
          },
        } = data;
        if (error) {
          setSecondaryData({
            ...secondaryData,
            error: true,
            errorMessage: message,
          });
        } else {
          setUserToken(token);
          setUserHash(hash);
          setRefreshToken(refresh_token);
          setDeviceKey(device_key);
          setNewUser(true);
          if (formData.promoCode) {
            if (window.location.href.includes('hexospark.com')) {
              //@ts-ignore
              window.dataLayer.push({
                event: 'appsumo-redeem',
                appsumoConversionId: `Appsumo_redeem_${new Date().toISOString()}`,
              });
            }
          }
          if (Math.round(Math.random()) && !getPackageInfo() && !state?.navigatePath) {
            setHandleCloseRegModal && setHandleCloseRegModal(true);
            setShowTwoFactor(true);
          } else {
            if (getPackageInfo()) {
              localStorage.setItem('navState', '/pricing/checkout/');
              getUser();
            } else if (state?.navigatePath) {
              localStorage.setItem('navState', state?.navigatePath);
              getUser();
            } else window.location.href = returnDashUrl();
          }
        }
      }
    } else {
      setSecondaryData({
        ...secondaryData,
        error: true,
        errorMessage: 'Please agree to the Terms of Service',
      });
    }
  }, [formData, TrackRegistration, registrationMutation, getPackageInfo, secondaryData, state]);

  const googleReg = useCallback(() => {
    if (getPackageInfo()) localStorage.setItem('fromLogonOrRegistrationPage', 'true');
    if (window.location.host.includes('37.186.119.181')) {
      window.location.href = `http://37.186.119.181:4000/api/app/social-callback/hexospark/google?referralCode=${referralCode}${
        getPackageInfo() ? '&redirect=pricing/checkout' : state?.navigatePath ? `&redirect=${state.navigatePath.slice(1)}` : ''
      }&deviceKey=${getRawCookie('device_key') || ''}`;
    } else if (window.location.host.includes('localhost')) {
      window.location.href = `http://37.186.119.181:4000/api/app/social-callback/hexospark/google?referralCode=${referralCode}${
        getPackageInfo() ? '&redirect=pricing/checkout' : state?.navigatePath ? `&redirect=${state.navigatePath.slice(1)}` : ''
      }&deviceKey=${getRawCookie('device_key') || ''}`;
    } else {
      window.location.href = `https://api.hexospark.com/v2/app/social-callback/hexospark/google?referralCode=${referralCode}${
        getPackageInfo() ? '&redirect=pricing/checkout' : state?.navigatePath ? `&redirect=${state.navigatePath.slice(1)}` : ''
      }&deviceKey=${getRawCookie('device_key') || ''}`;
    }
  }, [getPackageInfo]);
  //!-------------------------------------------
  return (
    <div className="registration-block">
      {showTwoFactor ? (
        <ChangeToFactor />
      ) : (
        <>
          <h4 className="title">Create your account</h4>
          {/* <h5 className="sub-title">Get your free account now!</h5> */}
          <button className="gmail-btn" onClick={googleReg}>
            <img src={googleIcon} alt="Login with Google" className="google-icon" /> <span>Sign Up with Google</span>
          </button>
          <div className="or-div">
            <div>
              <Translate name="or" />
            </div>
            <hr />
          </div>
          <div className="forms">
            <div className="wrapper">
              <div className="form-item">
                <Input
                  required
                  placeholder="First Name"
                  type="text"
                  name="firstName"
                  onChange={fieldChangeHandler}
                  value={formData.firstName}
                  maxLength={255}
                  autocomplete={true}
                  label="First Name"
                />
              </div>
              <div className="form-item">
                <Input
                  required
                  placeholder="Last Name"
                  type="text"
                  name="lastName"
                  onChange={fieldChangeHandler}
                  value={formData.lastName}
                  maxLength={255}
                  autocomplete={true}
                  label="Last Name"
                />
              </div>
            </div>
            <div className="form-item">
              <Input
                required
                placeholder="Email"
                label="Email"
                type="email"
                name="email"
                onChange={fieldChangeHandler}
                value={formData.email}
                maxLength={320}
                autocomplete={true}
              />
            </div>
            <div className="form-item position-relative password-form-reg">
              <Input
                required
                placeholder="Password (minimum 8 symbols)"
                label="Password"
                type={!showPassword ? 'password' : 'text'}
                name="password"
                onChange={fieldChangeHandler}
                value={formData.password}
              />
              <span className={`eye-icon-reg ${showPassword ? ' active' : ''}`} onClick={setShowPassword}>
                {eyeIcon}
              </span>
            </div>
            {formData.referralCode && (
              <div className="form-item mb-0">
                <Input
                  // style={{ border: "none" }}
                  placeholder="Referral code (optional)"
                  label="Referral code"
                  type="text"
                  name="referralCode"
                  onChange={fieldChangeHandler}
                  value={formData.referralCode}
                  maxLength={255}
                />
              </div>
            )}

            <div className="form-item mb-0">
              <Input
                placeholder="Code (optional)"
                label="Code"
                type="text"
                name="promoCode"
                onChange={fieldChangeHandler}
                value={formData.promoCode}
              />
            </div>

            <div className={`${passwordMessage || secondaryData.errorMessage ? 'visible' : 'invisible'} `}>
              <Message
                type="error"
                text={
                  passwordMessage
                    ? 'Your password should consist of at least 8 characters including at least 1 digit, 1 capital letter and at least 1 special character'
                    : limitExceed
                    ? 'Maximum allowed password length is 64 characters.'
                    : <Translate name={secondaryData.errorMessage} /> || secondaryData.errorMessage
                }
              />
            </div>
          </div>
          <div className="terms">
            <div className="toggle-checkbox">
              <Checkbox checked={secondaryData.checked} click={checkHandler} />
              <span className="text">I agree to the&nbsp;</span>
              <span className="terms-text">
                <a
                  href="https://hexospark.com/terms-of-use"
                  target="_blank"
                  aria-label={'https://hexospark.com/terms-of-use'}
                >
                  Terms of Service
                </a>
              </span>{' '}
              <span className="text-danger">*</span>
            </div>
          </div>
          <div className="submit-block">
            <button className="submit-btn w-100" onClick={submitHandler}>
              {loading ? <FontAwesomeIcon icon={faSpinner} className="button-loading" /> : <Translate name="SIGN_UP" />}
            </button>
          </div>
          <div className="sign-in">
            <span className="text">
              <Translate name="ALREADY_MEMBER" />
            </span>
            <span className="sign-in-text" onClick={toLogin}>
              <Translate name="Sign in" />
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default Registration;
