import React, {FC, useContext, useEffect} from 'react';
import {navigate} from 'gatsby';
import {UserContext} from '../../contexts/user-context';
import SimpleLayout from '../../components/layout_simple';
import Registration from '../../components/registration';
import './styles.scss';

const RegistrationPage: FC<any> = ({location}) => {
  const {state = {}} = location;
  const {user} = useContext(UserContext);
  useEffect(() => {
    if (user && user.id) {
      navigate('/');
    }
  }, [user]);

  return (
    <SimpleLayout isClickable>
      <Registration state={state} />
    </SimpleLayout>
  );
};

export default RegistrationPage;
